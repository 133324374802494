import React, { useEffect, useState, useContext } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "./styles.css"
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link, useParams } from "react-router-dom";
import { Loading } from '../components/Loading';
import languageContext from "../components/change-language"
import AdsComponent from '../components/AdsComponent';
import ThemeContext from "../components/themeContext";
import Footer from "../components/Footer"


const Home = ()=>{
    const [posts, setPosts] = useState([{}])
    const [loading, setLoading] = useState(true)
    const FOTOS = "https://nathivos.com.br/backend/fotos/"
    let listItems = "";
    const {language} = useContext(languageContext);
    const {dark} = useContext(ThemeContext);
    const { menu } = useParams();
    const { sub } = useParams();
    const voidItems = language === "en"? <div className="content_post"><div className="pg_not_found">Error 404<br/>Page not found!</div> </div>
    :<div className="content_post"><div className="pg_not_found">Erro 404<br/>Página não encontrada!</div> </div>
    
    useEffect(()=>{
        const link = menu === undefined ? ("https://www.nathivos.com.br/backend/select/u446777955_app/main_post/where/status/igual/1/orderby/rand()/desc/limit/4"):(
                      sub === undefined ? ("https://nathivos.com.br/backend/select/u446777955_app/main_post/where/type/igual/" + menu ):(
                                           "https://nathivos.com.br/backend/select/u446777955_app/main_post/where/subtype/igual/" + sub )
                                            )        
        try {
            fetch(link)
            .then(r => r.json())
            .then(r => {
                if(r.status === 'erro'){
                    setPosts([{}])
                }else{
                    setPosts(r)
                }                
                setLoading(false)
            })
        } catch (error) {
            
        }
    },[menu, sub])
    
    const handleTitle = (pt, en) =>{
        document.title = (language === "pt" ? pt : en)
    }


    try {
        listItems = posts.filter(p=>p.status === "1").map((p) =>
            <div className="content_post"  key={Math.random()}>
                <aside className="aside"><AdsComponent dataAdSlot="3088029193"/></aside>
                <Link className= {dark ? "post_dark":"post"} to= {"/"+ p.type + "/" + p.subtype + "/" + p.link}  onClick={()=>handleTitle(p.name_pt, p.name_en)}>
                    <h1 className="name">{language === "pt" ? p.name_pt : p.name_en}</h1>
                    <div className="image_content">
                        <div className="image">
                            <LazyLoadImage
                                alt=""
                                effect="blur"
                                src={(FOTOS + p.photo)}
                                width="100%"
                            /> 
                        </div>
                    </div>
                    <div className="short_description">
                        {language === "pt" ? p.description_pt : p.description_en}
                    </div>
                    <div className="lermais">
                        {language === "pt" ? "Leia mais..." : "Read more..."}
                    </div>
                    
                    <AdsComponent dataAdSlot="3088029193"/>
                </Link>
                <aside className="aside"><AdsComponent dataAdSlot="3088029193"/></aside>
            </div>
        );
    } catch (error) {
       console.log("Erro no try");
    }
    
    return(
        <main className={dark ? "content_dark":"content"} >
            <section className="mainsection"> 
                {loading ? <Loading/> : listItems.length === 0 ? voidItems:listItems}
            </section>
            <Footer/>
        </main>
    )
}
export default Home;