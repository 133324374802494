import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import "./styles.css"


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "95%",
    maxWidth: 1000,
    bgcolor: '#EFEFEF',
    border: 'none',
    backgroundColor: 'transparent',
    borderRadius: 2,
    boxShadow: 24,
    p: 0,
    overflow: "hidden",
    margin: 0
};

export default function ModalFotoWiew({ open, handleClose, listaFotos}) {
    const FOTOS = "https://nathivos.com.br/backend/fotos/"
    const[index, setIndex] = useState(0)
    

    const next = ()=>{
        if(index < listaFotos.length - 1){
            setIndex(index + 1)
        }else{
            setIndex(0)
        }
    }
    const previos =()=>{
        if(index > 0 ){
            setIndex(index - 1)
        }else{
            setIndex(listaFotos.length - 1)
        }
    }
    
    return (
        <div key={"02183766137"}>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {!!open && 
                        <>
                            <div className='foto_modal'>
                                <img src={FOTOS + listaFotos[index].photo} alt={listaFotos[index].alt} />
                            </div>
                            <div className="name_modal">{listaFotos[index].alt}</div>
                            <div className="description_modal">{listaFotos[index].legend}</div>
                        </>
                    }
                    {listaFotos.length > 1 && 
                        <>
                            <div className="anterior" onClick={previos}>
                                <i className="fa fa-chevron-left fa-3x" aria-hidden="true"></i>
                            </div>
                            <div className="proximo" onClick={next}>
                                <i className="fa fa-chevron-right fa-3x" aria-hidden="true"></i>
                            </div>
                        </>
                    }
                </Box>
            </Modal>
        </div>
    );
}
