
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { useContext } from 'react';
import ThemeContext from "../components/themeContext";

export default function Ratingview({ rating }) {
    const { dark } = useContext(ThemeContext)
    return (
        <div>
            <Box sx={{'& > legend': { mt: 2 }, }}>
                <Rating 
                    name="read-only" 
                    value={rating} 
                    readOnly 
                    precision={0.5}
                    emptyIcon={dark && <StarIcon style={{ color: "white" }} />}
                />
            </Box>
        </div>
    );
}
