import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import './styles.css';
import brazil from '../assets/brazil.png';
import germany from '../assets/deutchland.png';
import england from '../assets/england.png';
import languageContext from "./change-language";
import ThemeContext from "./themeContext";
import ReactGA from "react-ga4";
import Cookies from 'js-cookie';

ReactGA.initialize("UA-180861541-1");

const Navbar = () => {
    const { language, setLanguage } = useContext(languageContext);
    const { dark, setDark } = useContext(ThemeContext);
    const [submenu, setSubmenu] = useState([{ id_menu: "", name_pt: "", name_en: "" }])
    const [menu, setMenu] = useState([{}])
    const [menuclass, setMenuclass] = useState(["menu2", "menu2"])
    const [menu1, setMenu1] = useState("menu2")
    const mn = useParams().menu;


    useEffect(() => {
        fetch("https://nathivos.com.br/backend/select/u446777955_app/main_menu")
            .then(r => r.json())
            .then(r => {
                setMenu(r)
            })
        fetch("https://nathivos.com.br/backend/select/u446777955_app/sub_menu")
            .then(r => r.json())
            .then(r => {
                setSubmenu(r)
            })
    }, [])

    const handleTitle = (pt, en) =>{
        document.title = (language === "pt" ? pt : en)
    }
    const handleTheme = () => {
        Cookies.set('theme', !dark);
        setDark(!dark)
    }
    const handleMenu = () => {
        setMenuclass(menuclass[0] === "menu" ? ["menu2", "menu2"] : ["menu", "menu_dark"]);
        setMenu1(menu1 === "menu1" ? "menu2" : "menu1")
    }
    return (
        <nav className={dark ? "navbar_dark" : "navbar"} key={Math.random()}>
            <header className="logo">
                <div className={dark ? "logo_text_dark" : "logo_text"}>
                    Nathivo's
                </div>
                <div className={dark ? "theme_box_light" : "theme_box_dark"} title={dark ? "Disable Dark mode" : "Enable Dark mode"}
                    onClick={() => handleTheme()}>
                    {!dark && <i className="fa fa-moon-o fa-lg"></i>}
                    {dark && <i className="fa fa-sun-o fa-lg" ></i>}
                </div>
            </header>
            <div className="menu_mobil" onClick={handleMenu}>
                <i className="fa fa-bars fa-2x" aria-hidden="true"></i>
            </div>
            <div className={menu1} onClick={() => { setMenuclass(["menu2", "menu2"]); setMenu1("menu2") }}>
                <div className={dark ? menuclass[1] : menuclass[0]} >
                    <div className="dropdown" key="01212">
                        <Link to={"/"} className={dark ? "dropbtn menu_item_active_dark" : "dropbtn menu_item_active"} onClick={()=>handleTitle("Nathivo's", "Nathivo's")}>
                            <span  className={mn === undefined ? "active": ""}>{language === "pt" ? "Início" : "Home"}</span>
                        </Link>
                    </div>
                    {menu?.map((itm, a) =>
                        <div className="dropdown" key={a}>
                            <Link to={"/" + itm.link} className={dark ? "dropbtn menu_item_dark" : "dropbtn menu_item"} onClick={()=>handleTitle(itm.name_pt, itm.name_en)}>
                                <span  className={itm.link === mn ? "active": ""}>{language === "pt" ? itm.name_pt : itm.name_en}</span>
                            </Link>
                            <div className={dark ? "dropdown-content_dark" : "dropdown-content"}>
                                {submenu.filter((item) => item.link_menu === itm.link).map((item, i)=>
                                    <Link to={"/" + itm.link + "/" + item.link} className={dark ? "dropdown_item_dark" : "dropdown_item"} key={i} onClick={()=>handleTitle(item.name_pt, item.name_en)}>
                                        <span>{language === "pt" ? item.name_pt : item.name_en}</span>
                                    </Link>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="dropdowni" >
                        <button className="dropbtn" onClick={e => e.stopPropagation()}><img src={language === "pt" ? brazil : (language === "en" ? england : germany)} alt="" /></button>
                        <div className={dark ? "dropdown-contentr_dark" : "dropdown-contentr"}>
                            <div className={dark ? "dropdown_item_dark" : "dropdown_item"} onClick={() => { setLanguage("pt") }}>
                                <span>Português</span>
                                <img src={brazil} alt="" />
                            </div>
                            <div className={dark ? "dropdown_item_dark" : "dropdown_item"} onClick={() => setLanguage("en")}>
                                <span>English</span>
                                <img src={england} alt="" />
                            </div>
                            {/* <div className={dark ? "dropdown_item_dark" : "dropdown_item"} onClick={() => setLanguage("de")}>
                                <span>Deutsch</span>
                                <img src={germany} alt="" />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

        </nav>
    )
}
export default Navbar;