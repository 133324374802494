import './App.css';
import { Outlet } from 'react-router-dom';
import Navbar from './components/Navbar';
import React, { useState } from 'react';
import languageContext from "./components/change-language"
import ThemeContext from './components/themeContext';



function App() {
    const [language, setLanguage] = useState("pt")
    const [dark, setDark] = useState(false)
    
    return (
        <div className="app">
            <ThemeContext.Provider value={{dark, setDark}}>
                <languageContext.Provider value={{language, setLanguage}}>
                    <Navbar/>
                    <Outlet/>
                </languageContext.Provider>
            </ThemeContext.Provider>
        </div>
    );
}

export default App;
