import React, { useContext, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AdsComponent from '../components/AdsComponent';
import languageContext from "../components/change-language"
import ThemeContext from "../components/themeContext";
import Footer from "../components/Footer"
import Modal from '../components/Ratingmodal';
import ModalFotoWiew from "../components/ModalFotoWiew";
import Ratingview from "../components/Ratingview";
import "./styles.css"
import { Loading } from '../components/Loading';

const Posts = () => {
    const [error, setError] = useState("error")
    const [sub_post, setSub_post] = useState([{ id: 0, id_post: '', content_type: '', content: '', aux: '', language: '' }])
    const [rating, setRating] = useState({ media: 0, qtd: 0, status: "" })
    const [listaf, setListaf] = useState([{}])
    const [list, setList] = useState({})
    const [photo, setPhoto] = useState(0)
    const [open, setOpen] = useState(false);

    const { dark } = useContext(ThemeContext)
    const { language } = useContext(languageContext);
    const FOTOS = "https://nathivos.com.br/backend/fotos/"
    const { item } = useParams();
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false) }

    useEffect(() => {
        try {
            fetch("https://nathivos.com.br/backend/select/u446777955_app/sub_post/where/link_post/igual/" + item)
                .then(r => r.json())
                .then(r => {
                    if (r.status !== "erro") {
                        setSub_post(r)
                        setError("ok")
                        fetch("https://nathivos.com.br/backend/selectrating/u446777955_app/" + item)
                            .then(r => r.json())
                            .then(r => {
                                if (r.status === "sucesso") {
                                    setRating(r)
                                }
                            })
                    } else {
                        setError("error")
                        console.log("Erro final ", r);
                    }
                })
        } catch (error) {
            console.log("Erro catch");
        }

    }, [item])

    useEffect(() => {
        try {
            fetch("https://nathivos.com.br/backend/select/u446777955_app/photos")
                .then(r => r.json())
                .then(r => {
                    if (r.status === "erro") {
                        return
                    } else {
                        setListaf(r)
                    }
                })
        } catch (error) {

        }
    }, [])
    // ********************************************************************************************
    const title1 = (el, i)=>  i > 0 ? <h1 className="np_title1" key={i}> {language === "pt" ? el.content : el.content_en}</h1> : 
            <>
                <h1 className="np_title1" key={i}> {language === "pt" ? el.content : el.content_en}</h1>
                <span className="rating_content">
                    <Ratingview rating={rating.media} />
                    {rating.qtd === 0 ? (language === "pt" ? " Nenhuma avalição ":" No ratings") : rating.qtd === '1' ? (language === "pt" ? " 1 avalição": " 1 rating") : rating.qtd + (language === "pt" ?" avalições": " ratings")}
                </span>
                <span className="modal_content"><Modal name={language === "pt" ? "Avalie": "Rate"} link_post = {item}/></span>
            </>
    const title2 = (el, i) => <h2 className="np_title2" key={i}> {language === "pt" ? el.content : el.content_en}</h2>
    const title3 = (el, i) => <h4 className="np_title3" key={i}> {language === "pt" ? el.content : el.content_en}</h4>
    const paragrafo = (el, i) => <p className="np_paragrafo" key={i}> {language === "pt" ? el.content : el.content_en}</p>
    const foto = (el, i) => <div key={i} className="np_divfoto">
        <LazyLoadImage
            alt=""
            effect="blur"
            src={(FOTOS + el.aux)}
            width="100%"
        />
        <legend className="np_legend">{language === "pt" ? el.content : el.content_en}</legend>
    </div>
    const listFotos = (el, i) => <div key={i} className="np_divfoto">
        <div className="ed_coringacol">
            <h4>{language === "pt" ? el.content : el.content_en}</h4>
            <div className="ed_listafotos">
                {listaf && listaf.filter((itm) => itm.id_fcont === el.id).map((e, i) =>
                    ((i<4 &&  window.innerWidth >= 800)|| (i<2 &&  window.innerWidth <= 800)) && 
                        <div className={dark ? "ed_imgmini_dark":"ed_imgmini"} key={i} onClick={() => { handleOpen(); setPhoto(i); setList(listaf.filter((itm) => itm.id_fcont === el.id))}}>
                            <LazyLoadImage
                                alt={e.alt}
                                effect="blur"
                                src={FOTOS + e.photo}
                                height="100%"
                            />
                        </div>
                )}
            </div>
        </div>
        <ModalFotoWiew open={open} handleClose={handleClose} foto={photo} key={el?.id + 20} listaFotos={list} />
    </div>
    const linkinterno = (el, i) => <p className= {dark? "np_linkinterno_dark":"np_linkinterno"} key={i}><Link to={"/" + el.aux}> {language === "pt" ? el.content : el.content_en}</Link></p>
    const linkexterno = (el, i) => <p className={dark? "np_linkexterno_dark":"np_linkexterno"} key={i}><a href={el?.aux} target="_blank" rel="noopener noreferrer">{language === "pt" ? el.content : el.content_en}</a></p>
    const lista = (el, i) => {
        const conteudo = language === "pt" ? el.content : el.content_en;
        if (el?.id === undefined) { return }
        const lista = conteudo.split("\n").map((item, i) => {
            return <li key={i * 100}> {item}</li>
        })
        return (<ul className="np_lista" key={Math.random()}> {lista} </ul>)
    }

    const mapa = (el, i) => {
        return <div key={i} className="np_mapa" dangerouslySetInnerHTML={{ __html: el.content }} />
    }
    const video = (el, i) => {
        return <div key={i}  className="np_video" dangerouslySetInnerHTML={{ __html: el.content }} />
    }

    const objtohtml = (el, i) => {
        switch (el.content_type) {
            case "title1":
                return title1(el, i)
            case "title2":
                return title2(el, i)
            case "title3":
                return title3(el, i)
            case "paragrafo":
                return paragrafo(el, i)
            case "foto":
                return foto(el, i)
            case "fotos":
                return listFotos(el, i)
            case "lista":
                return lista(el, i)
            case "linkinterno":
                return linkinterno(el, i)
            case "linkexterno":
                return linkexterno(el, i)
            case "mapa":
                return mapa(el, i)
            case "video":
                return video(el, i)
            default:
                return
        }
    }
    const renderSubposts = (error === "ok") && sub_post?.map((el, i) => {
        return objtohtml(el, i)
    })

    return (
        <main className={dark ? "content_dark" : "content"}>
            <section className="mainsection">
                {(error === "ok") &&
                    <div className="content_post">
                        <aside className="aside"> <AdsComponent dataAdSlot="3088029193" /> </aside>
                        <article className={dark ? "post_details_dark" : "post_details"}>
                            {renderSubposts}
                        </article>
                        <aside className="aside"> <AdsComponent dataAdSlot="3088029193" /> </aside>
                    </div>
                }
                {(error === "error") && (
                    <article key={"283883"}>
                        <Loading/>
                    </article>
                )}
            </section>
            <Footer />
        </main>
    )
}
export default Posts;